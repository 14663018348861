import React from 'react';
import { Card, Typography } from '@mui/material';

const ProfileCell = ({ title, content }) => {
    return (
        <Card
            sx={{
                bgcolor: '#F1F2F6', // Цвет фона
                borderRadius: 2, // Закругленные углы
                boxShadow: 0, // Тень

                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: {xs: 1, sm: 2, md: 3}, // Отступы внутри карточки
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: 'bold', // Жирный текст для заголовка
                    color: 'text.primary', // Основной цвет текста
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: 'normal', // Обычный текст для контента
                    color: 'text.secondary', // Второстепенный цвет текста
                    textAlign: 'right', // Выровнять по правому краю
                }}
                dangerouslySetInnerHTML={{ __html: content }}
            >
            </Typography>
        </Card>
    );
};

export default ProfileCell;