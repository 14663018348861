// ClipboardCell.js
import React, { useState } from "react";
import { TextField, IconButton, Box, Snackbar, Alert } from "@mui/material";
import { ContentCopyRounded as CopyIcon } from "@mui/icons-material";
import { Check as CheckIcon } from "@mui/icons-material";

const ClipboardCell = ({ text }) => {
    const [copied, setCopied] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            console.log("Copied to clipboard:", text);
            setCopied(true);
            setOpenSnackbar(true);

            // Через 2 секунды возвращаем иконку и скрываем Snackbar
            setTimeout(() => {
                setCopied(false);
                setOpenSnackbar(false);
            }, 2000);
        });
    };

    return (
        <>
            <Box
                component="form"
                sx={{ p: 0, display: 'flex', alignItems: 'center' }}
            >
                <TextField
                    color="secondary"
                    focused
                    defaultValue={text}
                    size="small"
                    sx={{ p: 0 }}
                />

                <IconButton
                    color="secondary"
                    sx={{ p: 1 }}
                    aria-label="copy"
                    onClick={handleCopy}
                >
                    {copied ? <CheckIcon /> : <CopyIcon />}
                </IconButton>
            </Box>

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // например, вверху по центру

            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard: {text}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ClipboardCell;