import React, { useState } from 'react';
import {
    Dialog,
    Typography,
    Button,
    Box,
    Card,
    CardContent,
    Backdrop,
    CircularProgress
} from '@mui/material';
import RegionCard from "./RegionCard";
import { ReactComponent as StepsIcon } from '../assets/steps.svg';
import ClipboardCell from "./ClipboardCell";
import Skeleton from '@mui/material/Skeleton';
import { fetchGoto } from '../services/api';

const RegionDetails = ({
                           open,
                           onClose,
                           title,
                           subtitle,
                           icon,
                           extraInfo,
                           routeInfo,
                           steps,
                           commandValue,
                           canTravel,
                           isLoading,
                           token,
                           pointId,
                           onShowSnackbar
                       }) => {
    const showSkeleton = isLoading || !title || !subtitle;
    const [isTraveling, setIsTraveling] = useState(false);

    const handleGotoClick = () => {
        setIsTraveling(true);
        fetchGoto(token, pointId)
            .then(data => {
                // Предполагается что ответ от сервера в случае 200 содержит { result: "..." }
                onClose(); // Закрываем диалог
                setIsTraveling(false);
                onShowSnackbar(data.result, "success");
            })
            .catch(err => {
                console.error("Error on goto:", err);
                onClose();
                setIsTraveling(false);

                // Если есть err.result, используем его, если нет, fallback на err.message или "error"
                const errorMessage = err.result || err.message || "error";
                onShowSnackbar(errorMessage, "error");
            });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
                {showSkeleton ? (
                    // Показываем скелетон на весь экран
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        p: 2
                    }}>
                        <Skeleton variant="rectangular" height={50} />
                        <Skeleton variant="rectangular" height={100} />
                        <Skeleton variant="rectangular" height={100} />
                        <Skeleton variant="rectangular" height={50} />
                    </Box>
                ) : (
                    // Когда данные загружены, показываем контент
                    <Box
                        sx={{
                            bgcolor: 'background.default',
                            overflow: 'visible',
                            py: { xs: 2, sm: 3, md: 4 },
                            px: { xs: 1, sm: 2, md: 4 },
                            boxSizing: 'border-box',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: { xs: 2, sm: 3, md: 4 },
                        }}
                    >
                        <RegionCard titleLabel={title} subtitle={subtitle} imageFileName={icon}></RegionCard>

                        {extraInfo !== "-" && (
                            <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 0,
                                }}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        dangerouslySetInnerHTML={{ __html: extraInfo }}
                                    />
                                </CardContent>
                            </Card>
                        )}

                        <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{ __html: routeInfo }}
                                />

                                <Button
                                    variant="contained"
                                    size="medium"
                                    color={'secondary'}
                                    onClick={handleGotoClick}
                                    sx={{ alignSelf: 'flex-start' }}
                                    disabled={canTravel === "false"}
                                >
                                    <b>{steps}</b>
                                    <StepsIcon style={{ fill: 'currentColor', width: '20px', height: '20px', marginLeft: '8px' }} />
                                </Button>
                            </CardContent>
                        </Card>

                        <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Typography variant="body2" color="text.secondary">
                                    Bot Command
                                </Typography>
                                <ClipboardCell text={commandValue}></ClipboardCell>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Dialog>

            {/* Бэкдроп с лоадером, показывается пока идет запрос */}
            <Backdrop open={isTraveling} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default RegionDetails;