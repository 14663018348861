// src/services/api.js
export const connectSSE = (token, updateHandlers, onError, tab) => {
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}v2/events?token=${encodeURIComponent(token)}&tab=${encodeURIComponent(tab)}`);


    eventSource.onmessage = (e) => {
        try {
            let updates = JSON.parse(e.data);
            if (!Array.isArray(updates)) {
                throw new Error('Invalid data format');
            }

            updates.forEach(({ key, value }) => {
                if (key in updateHandlers) {
                    updateHandlers[key](value);
                } else {
                    console.error(`Unknown key: ${key}`);
                }
            });
        } catch (error) {
            console.error('Error processing SSE message:', error);
            document.body.innerHTML = `<h1 style="color: red; text-align: center;">Internal Error</h1>`;
        }
    };

    eventSource.onerror = (err) => {
        console.error('EventSource failed:', err);
        eventSource.close();
        if (onError) onError(err);
    };

    return eventSource;
};
export const sendTabChange = (token, activeTab) => {
    fetch(`${process.env.REACT_APP_API_URL}v2/tabchange?token=${encodeURIComponent(token)}&activeTab=${encodeURIComponent(activeTab)}`, {
        method: 'GET', // Изменено с POST на GET
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    })
        .then(response => {
            if (!response.ok) {
                console.error('Failed to change tab:', response.statusText);
            }
        })
        .catch(error => {
            console.error('Error changing tab:', error);
        });
};

// Новый метод для получения данных региона
export const fetchRegionData = async (token, pointId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}v2/region`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ pointId })
    });

    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }

    return response.json();
};

export const fetchGoto = async (token, pointId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}v2/goto`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ pointId })
    });

    if (!response.ok) {
        // Пытаемся прочитать тело ошибки и достать result
        let errorResult = 'error';
        try {
            const data = await response.json();
            if (data && data.result) {
                errorResult = data.result;
            }
        } catch (e) {
            // Если JSON не удалось прочитать, оставляем errorResult = 'error'
        }

        const err = new Error(errorResult);
        err.response = response;
        err.result = errorResult;
        throw err;
    }

    return response.json();
};