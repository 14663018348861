// src/components/MapTab.js
import React, { useContext } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Импортируем Grid2 (Grid version 2)
import { AuthContext } from '../contexts/AuthContext';
import { connectSSE, sendTabChange } from '../services/api'; // Добавьте sendTabChange
import { useState, useEffect } from 'react'; // Импортируйте useState и useEffect
import MapCell from './MapCell';
import RegionCard from "./RegionCard";
import { parseMessage } from '../utils/markdown';

// Теперь можно использовать parseMessage(message) в нужном месте
const MapTab = () => {
    const token = useContext(AuthContext);
    const [cells, setCells] = useState([]); // Добавьте состояние для ячеек карты
    const [mapOnlineCount, setMapOnlineCount] = useState(0);
    const [titleLabel, setCurrentTitleLabel] = useState("-");
    const [subtitleLabel, setCurrentSubtitleLabel] = useState("-");
    const [iconFileName, setIconFileName] = useState("");


    useEffect(() => {
        if (!token) return; // Если нет токена, не выполняем побочные эффекты

        // Отправляем запрос на смену активной вкладки на 'map'
        sendTabChange(token, 'map');

        const updateHandlers = {
            map_cells: setCells, // Обработчик для 'map_cells'
            map_online_count: setMapOnlineCount,
            map_title_label: setCurrentTitleLabel,
            map_subtitle_label: (value) => setCurrentSubtitleLabel(parseMessage(value)),
            map_region_icon : setIconFileName,
            // Можно добавить другие обработчики, если необходимо
        };

        const eventSource = connectSSE(token, updateHandlers, (err) => {
            console.error('SSE connection error:', err);
        }, 'map'); // Передаём 'map' как параметр tab

        return () => {
            eventSource.close();
            // Опционально: отправьте запрос на смену вкладки, если необходимо
        };
    }, [token]);

    // Условный рендеринг: если нет токена, показываем загрузку
    if (!token) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6">Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                overflow: 'visible', // Гарантируем видимость
                py: { xs: 2, sm: 3, md: 4 },
                px: { xs: 1, sm: 2, md: 4 }, // Адаптивные горизонтальные отступы
                boxSizing: 'border-box',
                width: '100%', // Занимаем всю доступную ширину
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 2, sm: 3, md: 4 }, // Отступ между карточками
            }}
        >
            <RegionCard titleLabel={titleLabel} subtitle={subtitleLabel} imageFileName={iconFileName}></RegionCard>

            {/* Вторая карточка с сеткой 7x7 и горизонтальной прокруткой */}
            <Card
                sx={{
                    borderRadius: 2,
                    overflowX: 'auto', // Прокрутка по горизонтали
                    overflowY: 'visible', // Разрешаем расширение по вертикали без прокрутки
                    width: '100%', // Занимаем всю доступную ширину
                    boxShadow: 'none'
                }}
            >
                <CardContent
                    sx={{
                        px: { xs: 0, sm: 1, md: 2 }, // Адаптивные горизонтальные внутренние отступы
                        py: { xs: 2, sm: 3, md: 4 }, // Адаптивные вертикальные внутренние отступы
                    }}
                >

                    <Grid container spacing={0} columns={7}>
                        {cells.map(cell => (
                            <Grid key={cell.pointId} size={1}>
                                <MapCell
                                    pointId={cell.pointId}
                                    chipColor={cell.chipColor}
                                    chipLabel={cell.chipLabel}
                                    bottomLabel={cell.bottomLabel}
                                />
                            </Grid>
                        ))}
                    </Grid>


                </CardContent>
            </Card>


            {/* Первая карточка с заглушкой */}
            <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none' }}>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Current online: {mapOnlineCount}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default MapTab;