import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Skeleton } from "@mui/material";

export default function RegionCard({ titleLabel, subtitle, imageFileName }) {
    const theme = useTheme();
    const [imageLoaded, setImageLoaded] = React.useState(false);

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 'auto',
                position: 'relative',
                overflow: 'visible',
                boxShadow: 'none',
            }}
        >
            <Box
                sx={{
                    width: { xs: `75%`, sm: `85%`, md: `90%` },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: theme.spacing(2),
                }}
            >
                <Typography
                    variant="subtitle2"
                    dangerouslySetInnerHTML={{ __html: titleLabel }}
                />
                <Typography
                    variant="subtitle2"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
            </Box>

            <Box
                sx={{
                    width: { xs: `25%`, sm: `15%`, md: `10%` }, // Ширина 35% для изображения
                    position: 'relative',
                    height: 'auto', // Высота задается через пропорции изображения
                    aspectRatio: '1', // Устанавливаем соотношение сторон 1:1 (квадрат)
                }}
            >
                {imageFileName && (
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            top: 'auto',
                            borderRadius: 1,
                        }}
                        image={`/img/map/${imageFileName}`}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setImageLoaded(true)}
                    />
                )}

                {/* Если нет имени файла или картинка еще не загрузилась, показываем скелетон поверх */}
                {(!imageFileName || !imageLoaded) && (
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            top: 0,
                            borderRadius: 1,
                        }}
                    />
                )}
            </Box>
        </Card>
    );
}